<template>
  <div class="echart" :id="chartId" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: "pies",
  props: {
    chartId: String,
  },
  data(){
    return {
      color: ['#8B5CFF'],

    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById(this.chartId));
      let option = {
        color: ['rgba(23, 184, 171', 'rgba(119,61,190', 'rgba(65,133,240'],
        tooltip: {
          trigger: 'item'
        },
        label: {
          color: '#FFFFFF'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            color:['rgb(23,184,171)', 'rgb(119,61,190)','rgb(65,133,240)'],
            tooltip: {
              trigger: 'item',
              formatter: '{b} ({d}%)',

            },
            radius: ['40%', '70%'],
            center: ['50%', '50%'],
            type: 'pie',
            label: {
              position: 'inner',
              formatter: (e) => {
                return e.name + '：' + e.value + '\n' + e.name + '：' + e.percent + '%'
              },
              show: true,
              fontSize: 20,
              lineHeight: 28,
            },
            labelLine: {
              normal: {
                show: true,
                length: 30,
                length2: 55
              },
              emphasis: {
                show: false
              }
            },
            data: [
              { value: 1048, name: '存量满足占比' },
              { value: 735, name: '缺口占比' },
              // { value: 580, name: 'Email' },
              // { value: 484, name: 'Union Ads' },
              // { value: 300, name: 'Video Ads' }
            ]

          }
        ]
      };
      getchart.setOption(option);
    }
  }
}
</script>

<style scoped>

</style>