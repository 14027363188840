<template>
  <div class="echart" id="echart-degreeDistributionBar" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'degreeDistributionBar',
  data() {
    return {
      labelOption: '',
      app: {},
      charts: { // 按顺序排列从大到小 degreeDistribution
        cityList: ['本科', '研究生', '博士', '其他'],
        cityData: [78, 68, 62, 57]
      },
      color: [['#4185F0','#4185F0'], ['#00F8F2','#00F8F2'], ['#C69CFF','#C69CFF'], ['rgba(244,232,165)','rgba(244,232,165)']],
      lineY:[],
      BgY:[],
      top10CityData:[],
      top10CityList:[],
    };
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-degreeDistributionBar'));
      var degreeDistributionBarOption = {
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          top: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params[0].name + '<br/>' + params[0].seriesName + ' : ' + params[0].value + '<br/>'
          }
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
          },
          data: this.charts.cityList
        }],

        series: [{
          name: '数量',
          type: 'bar',
          zlevel: 1,
          barWidth: 8,
          data:this.lineY
        },
          {
            name: '背景',
            type: 'bar',
            //barWidth: 8,
            barGap: '-100%',
            data: this.BgY,
          },
        ]
      };

      getchart.setOption(degreeDistributionBarOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },

    getData(){
      this.lineY= [];
      this.BgY = [];
      let top10CityList = this.charts.cityList
      let top10CityData = this.charts.cityData
      for (var i = 0; i < this.charts.cityList.length; i++) {
        var x = i
        if (x > this.color.length - 1) {
          x = this.color.length - 1
        }
        var data = {
          name: this.charts.cityList[i],
          value: top10CityData[i],
          label: {
            // normal: {
            //   color: this.color[x][1],
            //   show: true,
            //   position: [0, '-16px'],
            //   textStyle: {
            //     fontSize: 11
            //   },
            //   formatter: function(a, b) {
            //     return a.name
            //   }
            // }
          },
          itemStyle: {
            normal: {
              show: true,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: this.color[x][0]
              }, {
                offset: 1,
                color: this.color[x][1]
              }], false),
              barBorderRadius: 10
            },
            emphasis: {
              shadowBlur: 15,
              shadowColor: 'rgba(0, 0, 0, 0.1)'
            }
          }
        }
        var bdata = {
          name: this.charts.cityList[i],
          value: 100,
          itemStyle: {
            normal: {
              show: true,
              color: 'rgba(255,255,255,0.4)',
              // borderColor: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
              //   offset: 0,
              //   color: this.color[x][0]
              // },
              //   {
              //   offset: 1,
              //   color: this.color[x][1]
              // }], false),
              barBorderRadius: 10
            },
            emphasis: {
              shadowBlur: 15,
              shadowColor: 'rgba(0, 0, 0, 0.1)'
            }
          }
        }
        this.lineY.push(data);
        this.BgY.push(bdata);
      }
    }

  },
  mounted() {
    this.getData();
    this.initChart();
  },
};
</script>

<style scoped></style>
